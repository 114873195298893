* {
  box-sizing: border-box;
}

body, h1, h2 {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

em {
  font-style: normal;
}

em, mark {
  background: #e9ddf0;
}

strong {
  color: #6634d1;
  font-size: .875rem;
  font-weight: bold;
}

.powered-by {
  text-align: center;
  margin-bottom: 1.5rem;
}

.header-logo {
  display: none;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 2rem 1.5rem 2rem 1.2rem;
}

.container-filters {
  height: 2rem;
  justify-content: space-between;
  margin-bottom: 2rem;
  display: flex;
}

.container-filters-left {
  align-items: center;
  display: flex;
}

.container-filters-left .container-filters-filter:not(:last-child) {
  margin-right: 1rem;
}

.container-filters-title {
  text-transform: uppercase;
  align-items: center;
  margin-right: 2rem;
  font-size: .875rem;
  font-weight: bold;
  display: inline-flex;
}

.container-filters-button {
  cursor: pointer;
  font: inherit;
  background: none;
  border: none;
  margin-top: 3px;
  margin-left: 1rem;
  padding: 0;
}

.container-filters-button svg {
  height: 20px;
  width: 24px;
}

.container-filters-panel {
  margin-bottom: 2rem;
}

.container-filters-panel-column-wrapper {
  justify-content: space-between;
  display: flex;
}

.container-filters-panel:not(.container-filters-panel--hidden) {
  padding-bottom: 1.75rem;
}

.container-filters-panel--hidden {
  max-height: 0;
  margin-bottom: 0;
  display: none;
}

.container-filters-panel-column {
  flex: 1;
}

.container-filters-panel-column:not(:last-child) {
  margin-right: 4rem;
}

.header .searchbox-container {
  box-sizing: content-box;
  color: #fff;
  height: 3rem;
  max-width: 986px;
  border-bottom: 2px solid #e9ddf033;
  align-items: center;
  margin: 1rem 0 2rem;
  padding: 0 1.5rem;
  display: flex;
}

.header .searchbox-container-input {
  width: 100%;
}

.header label[for="search-input"] {
  height: 22px;
}

.header .magnifier-icon {
  height: 22px;
  min-width: 22px;
  margin-right: 1rem;
  display: inline;
}

.header .ais-SearchBox-reset {
  display: none;
}

.header .ais-SearchBox-input {
  color: #6634d1;
  width: 100%;
  border: none;
  padding: 0;
  font-size: 1rem;
  font-weight: bold;
}

.header .ais-SearchBox-input::placeholder {
  color: #00000080;
  opacity: 1;
}

.header .ais-SearchBox-input:-ms-input-placeholder {
  color: #00000080;
}

.header .ais-SearchBox-input::-moz-placeholder {
  color: #00000080;
}

.previous-hits {
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
  display: none;
}

.previous-hits.previous-hits--visible {
  display: flex;
}

.previous-hits-message {
  color: #6e7070;
  margin: 0;
  font-size: .9rem;
}

.previous-hits-button {
  color: #6634d1;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #e6ebf7;
  border: none;
  border-radius: 4px;
  margin-left: 1rem;
  padding: .5rem 1rem;
  font-family: inherit;
  font-size: .75rem;
  font-weight: bold;
}

.previous-hits-button:hover {
  background-color: #d3daf6;
}

.card-link {
  color: unset;
  text-decoration: unset;
}

.card-link:hover .card {
  transform: scale(1.03);
}

.card {
  height: 100%;
  background-color: #fff;
  flex-direction: column;
  transition: transform .2s ease-out;
  display: flex;
}

.card-image, .card-image img {
  max-height: 182px;
}

.card-image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 16px;
}

.card-content {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.card-subject {
  color: #6634d1;
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: bold;
}

.card-title {
  margin: 1rem 0 .4rem;
  font-weight: bold;
  line-height: 1.63;
}

.card-mobile-footer {
  margin: 0;
}

.card-timestamp {
  color: #6e7070;
  font-size: .75rem;
}

.card-title mark {
  font-size: inherit;
}

.card-description {
  color: #6e7070;
  flex: 2;
  margin: 0 0 1rem;
  font-size: .875rem;
  line-height: 1.64;
}

.card-author {
  align-items: center;
  display: flex;
}

.card-author-avatar {
  width: 32px;
  height: 32px;
  border-radius: 9999px;
  margin-right: 8px;
  display: inline-block;
}

.card-author-name {
  font-weight: bold;
}

.card-author-job {
  color: #6e7070;
  font-weight: normal;
  display: block;
}

.card--placeholder {
  min-height: 350px;
}

.card--placeholder .card-image {
  min-height: 182px;
  background-color: #eee;
}

.infinite-hits-item--highlighted .card--placeholder .card-image {
  min-height: 350px;
}

.infinite-hits-no-results-container {
  max-width: 513px;
  flex-direction: column;
  align-items: center;
  margin: auto;
  display: flex;
}

.infinite-hits-no-results-paragraph {
  color: #000;
  text-align: center;
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.7;
}

.infinite-hits-end {
  text-align: center;
  padding: 5rem;
  font-size: 1rem;
  font-weight: bold;
}

.infinite-hits-end:after, .infinite-hits-no-results-container:after {
  content: "";
  height: 5px;
  width: 150px;
  background: linear-gradient(to left, #764ba2, #667eea);
  border-radius: 5px;
  margin: 1rem auto auto;
  display: block;
}

.ais-SearchBox-submit {
  color: #fff;
}

.header .ais-RefinementList {
  min-height: 31px;
}

.header .ais-RefinementList-list, .header .ais-CurrentRefinements-item {
  display: flex;
}

.header .ais-RefinementList-item:not(:last-child), .header .ais-CurrentRefinements-category:not(:last-child) {
  margin-right: .5rem;
}

.header .ais-CurrentRefinements-category:last-child {
  margin-right: 1rem;
}

.header .ais-RefinementList-item {
  padding-bottom: 0;
}

.header .ais-RefinementList-item--selected, .header .ais-RefinementList-checkbox, .header .ais-RefinementList-count {
  display: none;
}

.header .ais-CurrentRefinements-item {
  align-items: center;
}

.header .ais-CurrentRefinements-category, .header .ais-RefinementList-labelText {
  color: #6634d1;
  min-height: 31px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  background: #e6ebf7;
  border-radius: 4px;
  align-items: center;
  padding: .4rem 1rem;
  font-size: .75rem;
  font-weight: bold;
  display: flex;
}

.header .ais-CurrentRefinements-categoryLabel {
  white-space: nowrap;
}

.header .ais-RefinementList-labelText:hover {
  background-color: #d3daf6;
}

.header .ais-CurrentRefinements-category * {
  font-size: inherit;
}

.header .ais-CurrentRefinements-delete {
  margin-left: .5rem;
}

.topics-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 1.2rem;
  display: flex;
  overflow: scroll;
}

.topics-container::-webkit-scrollbar {
  display: none;
}

.topics-container .ais-RefinementList {
  padding-right: 1.2rem;
}

.container-filters-panel-mobile {
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #fff;
  padding: 1rem 1.8rem 157px;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.container-filters-panel-mobile .ais-NumericMenu-labelText, .container-filters-panel-mobile .ais-RefinementList-labelText {
  color: #6e7070;
  font-size: 1rem;
}

.container-filters-panel-mobile .ais-NumericMenu-radio, .container-filters-panel-mobile .ais-RefinementList-checkbox {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}

.container-filters-panel-mobile .ais-RefinementList-count {
  font-weight: bold;
}

.container-filters-header {
  justify-content: space-between;
  display: flex;
}

.container-filters-footer {
  width: 100%;
  height: 117px;
  background: #fff;
  border-top: 1px solid #e9ddf0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}

.container-filters-footer .see-results-button {
  width: 230px;
  height: 50px;
  cursor: pointer;
  background-color: #6634d1;
  border: none;
  border-radius: 4px;
}

.container-filters-footer .see-results-button .ais-Stats-text {
  color: #f3f3f3;
  font-size: 1rem;
  font-weight: bold;
}

.container-filters-panel-mobile .container-filters-title {
  margin-right: 0;
  font-size: 1.25rem;
}

.container-filters-panel-mobile .ais-ClearRefinements-button {
  color: #6634d1;
  text-transform: initial;
  min-height: 24px;
  background: none;
  padding: 0;
  font-size: .85rem;
  font-weight: bold;
}

.container-filters-panel-mobile .ais-ClearRefinements-button:before {
  content: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 15 14'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%236634D1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 1.65v3.9h3.9'/%3E%3Cpath d='M2.631 8.8a5.85 5.85 0 1 0 1.385-6.084L1 5.55'/%3E%3C/g%3E%3C/svg%3E%0A");
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.container-filters-panel-mobile .ais-ClearRefinements-button:hover {
  background: none;
}

.container-filters-panel-mobile .ais-Panel-header {
  margin-top: 2.5rem;
}

body.filtering {
  overflow: hidden;
}

.header .ais-CurrentRefinements-label {
  display: none;
}

.selected-topics-mobile .ais-CurrentRefinements-item:last-child {
  border-right: 1px solid #e9ddf0;
  margin-right: 1rem;
}

.selected-topics-mobile .ais-CurrentRefinements-category {
  color: #f3f3f3;
  background: #6634d1;
}

.ais-ClearRefinements-button {
  color: #6634d1;
  min-height: 31px;
  text-transform: uppercase;
  background-color: #e6ebf7;
  border-radius: 4px;
  padding: .5rem 1rem;
  font-size: .75rem;
  font-weight: bold;
}

.ais-ClearRefinements-button:hover {
  background-color: #d3daf6;
}

.ais-ClearRefinements-button--disabled {
  display: none;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.back-to-top {
  height: 2.5rem;
  width: 2.5rem;
  margin: 0 2.5rem 1rem auto;
}

.filtering .back-to-top {
  display: none;
}

.mentions {
  color: #dadada;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  text-align: center;
  background-color: #000000bf;
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.mentions p {
  margin: 0;
}

.mentions a {
  color: #aa93dd;
  font-weight: bold;
  text-decoration: none;
}

.mentions a:hover {
  opacity: .75;
}

@media (max-width: 899px) {
  [data-layout="desktop"] {
    display: none !important;
  }
}

/*# sourceMappingURL=index.0ca3d285.css.map */
